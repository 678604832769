import React from 'react'
import SectionWrapper from '@components/ui/wrappers/SectionWrapper'
import SectionHeader from '@components/ui/typography/SectionHeader'
import ButtonLink from '@components/ui/buttons/ButtonLink'
import styled from 'styled-components'

const ReadySection = ({
  scrollId,
  title,
  description,
  external,
  buttonText,
  to,
  href,
  targetBlank,
  sectionBackground,
  explain = false,
  explainText,
  descriptionMaxWidth = '600px',
}) => {
  return (
    <SectionWrapper sectionBackground={sectionBackground}>
      <SectionHeader title={title} description={description} descriptionMaxWidth={descriptionMaxWidth} />

      <ButtonWrapper id={scrollId}>
        <ButtonLink
          external={external}
          to={to}
          href={href}
          targetBlank={targetBlank}
          buttonText={buttonText}
          mdfontsize={'16px'}
          explain={explain}
          explainText={explainText}
        />
      </ButtonWrapper>
    </SectionWrapper>
  )
}

export default ReadySection

const ButtonWrapper = styled.div`
  padding: 20px 0;
`
